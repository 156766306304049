body {
	color: #505050;
	font-family: "Roboto", sans-serif;
	font-size: 1rem;
	font-weight: 400;
}

.main-menu a:hover,
.main-menu a:active {
	color: #01552c;
}

.main-menu a {
	color: #181818;
	/* padding: 38px 10px; */
	display: inline-block;
	margin: 0 10px;
	font-weight: 500;
	text-transform: capitalize;
	outline: none;
	font-size: 16px;
	font-family: 'Inter', sans-serif;
	text-decoration: none;

}


.top_menus {
	position: sticky;
	top: 0px;
	z-index: 99;
}

.top_men {
	position: sticky;
	background-color: #fff;
	z-index: 9999999999;
}

p {
	color: #2a2a2a;
	font-size: 16px;
	line-height: 28px;
	font-family: 'Inter', sans-serif;
}

.header-top,
.Banner-bg {
	background: #01552c;
}

.header-top .htop-area {
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.header-top .htop-area .htop-left .htop-information li {
	color: #fff;
	margin-right: 40px;
	padding: 8px 0;
	list-style: none;
	font-size: 14px;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
}

.header-top .htop-area .htop-left .htop-information {
	display: flex;
}


.header-top .htop-area .htop-right ul li a {
	color: #FFC907;
	font-size: 14px;
	margin-right: 15px;
	font-weight: 500;
	font-family: "Roboto", sans-serif;
	text-decoration: none;

}

.htop-right li {
	list-style: none;
}

.htop-information,
.htop-right ul {
	padding-left: 0px;
	margin-bottom: 0rem;
}

.padding-top {
	padding-top: 100px;
}

.padding-bottom {
	padding-bottom: 100px;
}


.head_h2 {
	font-size: 40px;
	line-height: 60px;
	color: #181818;
	font-weight: 700;
	font-family: 'Inter', sans-serif;
}

.head_h3 {
	font-size: 30px;
	line-height: 40px;
	color: #181818;
	font-weight: 700;
	font-family: 'Inter', sans-serif;
}

.head_h4 {
	font-size: 18px;
	line-height: 30px;
	font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #181818;
	font-weight: 700;
	font-family: 'Inter', sans-serif;
}


.banner-title H1 {
	font-size: 60px;
	line-height: 70px;
	margin-bottom: 22px;
	font-weight: 800;
	font-family: Roboto, sans-serif;
	color: #FFC907;
}

.banner-title span {
	color: #FFF;
	font-size: 55px;
	text-shadow: 0px 6.532px 6.532px rgba(0, 0, 0, 0.25);
	font-family: Roboto, sans-serif;
	font-weight: 700;
}

.banner-title p {

	font-size: 16px;
	line-height: 24px;
}

.banner-title .btn {
	padding: 12px 52px;
	border-radius: 50px;
	text-align: center;
	font-size: 15px;
	color: #fff;
	text-transform: capitalize;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	border: 1px solid #FFC907;
	background-color: #FFC907;
}

.banner-title .btn:hover {
	padding: 12px 52px;
	border-radius: 50px;
	text-align: center;
	font-size: 15px;
	color: #FFC907;
	text-transform: capitalize;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	border: 1px solid transparent;
	background-color: transparent;
	border: 1px solid #FFC907;
}

.banner-title .btn-2:hover {
	padding: 12px 52px;
	border-radius: 50px;
	text-align: center;
	font-size: 15px;
	color: #fff;
	text-transform: capitalize;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	border: 1px solid #FFC907;
	background-color: #FFC907;
	text-decoration: none;
	margin-left: 20px;
}

.banner-title .btn-2 {
	padding: 12px 52px;
	border-radius: 50px;
	text-align: center;
	font-size: 15px;
	color: #FFC907;
	text-transform: capitalize;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	border: 1px solid transparent;
	background-color: transparent;
	border: 1px solid #FFC907;
	text-decoration: none;
	margin-left: 20px;
}

.tab-box .nav-item {
	font-size: 18px;
	font-weight: bold;
	background: #ddf4e9;
	color: #ffc907;
}

.tab-box .nav-link {
	color: #000;
}

.tab-box {
	border: 0px;
}

.tab-content {
	border: 1px solid #dee2e6;
	padding: 20px;
	border-top: 0px;
}

.bg-image-6 {
	background: url(../public/images/06.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.bg-image-3 {
	background: url(../public/images/03.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.green2 {
	padding-left: 0rem;
}

.lte-item {
	transition: all 0.2s ease;
	text-align: center;
	position: relative;
	border: 2px solid #f2f0eafc;
	border-color: #F2F0EA;
	border-color: #f2f0eafc;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
	background-clip: border-box;
	padding: 30px 30px;

}

.lte-item:hover {
	border-color: #ffc907;
	cursor: pointer;
	color: #ffc907;

}

.lte-item img:hover {

	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-ms-transform: scale(1.05);
	-o-transform: scale(1.05);
	transform: scale(1.05);
	-webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 3.75);
	-moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 3.75);
	-o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 3.75);
	transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 3.75);

}

.franch {
	border-radius: 0px 80px 0px 0px;
}

.franch-2 {
	border-radius: 50px 0px 50px 0px;
}

.section-ul ul li {
	list-style: none;
	padding: 5px 0;
}

.section-wrapper li i {
	color: #ffc907;
	margin-right: 10px;
}

.section-ul ul {
	padding-left: 0rem;
}

.follow a {
	text-decoration: none;
	color: #ffc907;
	font-weight: 500;
	font-size: 18px;
}

.bg-image-1 {
	background: url("../public/images/01.jpg") no-repeat;
	background-size: cover;
	background-position: center;
}

.footer-top {
	background: url("../public/images/footer-bg.jpg");
	background-position: center top;
	/* background-size: cover; */
	padding-bottom: 300px !important;
}

.footer-bottom p {
	padding: 27px 0;
	margin-bottom: 0rem;
	font-weight: 600;
	color: #01552c
}

.footer-logo {
	margin-bottom: 32px;
}

.footer-post,
.footer-location {
	list-style: none;
	padding-left: 0rem;
}

.footer-post li,
.footer-location li {
	display: flex;
	align-items: center;
	align-items: baseline;

}

.post-content a {
	text-decoration: none;
	color: #01552c
}

.icon-part i {
	padding-top: 10px;
	color: #01552c
}

.footer-post li .post-thumb {
	overflow: hidden;
	border-radius: 4px;
	margin-right: 17px;
}

.footer-location li .icon-part {
	width: 30px;
}

.den-login-form-control {
	background: #FFFFFF;
	width: 100%;
	display: block;
	border: 0.5px solid #717377;
	border-radius: 8px;
	font-weight: 400;
	height: 50px;
	font-size: 1rem;
	padding: 0 1rem;
}

.height {
	height: 100px;
}

.gallery-video {
	background: url("../public/images/newslater-bg.png");
	background-repeat: no-repeat;
	background-size: contain;
}

.newslater-area {
	height: 613px;
	margin-bottom: -200px;
}

.pb-200 {
	padding-bottom: 200px;
}

.newslater-title {
	display: flex;
}

.newslater-area h5 {
	color: #fdcc0d;
}

.section-title h2 {
	font-size: 60px;
	padding-bottom: 0;
	margin-bottom: 0px !important;
	position: relative;
	font-weight: 800;
}

.newslater-area h2 {
	color: #fff;
}

.pt-120 {
	padding-top: 120px;
}


.videos_gallery {
	background-image: url("../public/images/video-img2.png");

}

.s-video-wrap {
	background-position: center;
	background-size: cover;
	height: 500px;
	display: flex;
	align-items: center;
	background-repeat: no-repeat;
	border-radius: 10px;
}

.s-video-content {
	width: 100%;
	text-align: center;
}

.s-video-content a {
	z-index: 1;
	font-size: 14px;
	text-align: center;
	border-radius: 50%;
	display: inline-block;
	margin-bottom: 15px;
	position: relative;
}

.s-video-content a::before {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	height: 120px;
	width: 120px;
	background: rgba(255, 255, 255, 0.09);
	z-index: -1;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	animation: pulse-border 1500ms ease-out infinite;
}

.newslater-area #contact-form4 {
	float: left;
	width: 95%;
	margin-bottom: 30px;
}

#contact-form4 {
	margin-top: 20px;
}

.newslater input {
	background: #fff !important;
	border: 1px solid #FFF !important;
	box-shadow: none !important;
	padding: 9px 20px !important;
	color: #000 !important;
	height: calc(2em + .75rem + 20px);
	width: 100% !important;
	border-radius: 0px !important;
	position: relative;
	z-index: 1;
	margin-right: 7px;
	border-radius: 8px !important;
}

.newslater-area #contact-form4 button {
	border-radius: 8px;
	background: #fdcc0d;
	color: #000;
	height: 55px;
	padding: 0 30px;
	z-index: 1;
	text-align: center;
	margin-top: 4px;
	margin-right: 4px;
	border: none;
}

.p-relative {
	position: relative;
}

#contact-form4 input {
	float: left;
	padding: 23px 30px;
	border: none;
	width: 75%;
}

.newslater button {
	position: absolute;
	top: 0;
	right: 0;
	background: #fff;
	color: #000;
}

.about-right .video-post .video-thumb {
	position: relative;
}

.about-right .video-post .video-thumb .video-icon {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	content: "";
}

.about-right .video-post .video-thumb .video-icon i {
	font-size: 50px;
	color: #fff;

}

.post-item .post-inner {
	padding: 20px;
}

.post-item {
	border-radius: 20px;

	box-shadow: 0px 19px 49.6px 0px #ECECEC;
	height: 100%;
}

.post-item .post-inner .post-content {
	width: calc(100% - 60px);
	padding-left: 20px;
}

.post-content h4 {
	text-decoration: none;
	color: #01552c;
	font-weight: 700;
	font-size: 21px;
	font-size: 20px;
	line-height: 30px;
}

.post-item img {
	width: 75px;
}

.pop-up .head_h4_text {
	color: #181818;
	font-weight: 700;
	font-size: 22px;
}

.pop-up .den-login-form-control {
	background: #FFFFFF;
	width: 100%;
	display: block;
	border: 0.5px solid #717377;
	border-radius: 8px;
	font-weight: 400;

	font-size: 1rem;
	padding: 0 1rem;
}


@media screen and (min-device-width: 1024px) and (max-device-width: 1300px) {

	.banner-title .btn,
	.banner-title .btn-2 {
		padding: 12px 28px;
		display: inline-block;

	}

	.banner-title H1 {
		font-size: 40px;
		line-height: 52px;
		margin-bottom: 22px;
		font-weight: 600;
		font-family: Roboto, sans-serif;
		color: #FFC907;

	}

	.banner-title span {
		color: #FFF;
		font-size: 35px;
		text-shadow: 0px 6.532px 6.532px rgba(0, 0, 0, 0.25);
		font-family: Roboto, sans-serif;
		font-weight: 500;
		margin-top: 20px;

	}

}


@media (max-width: 1023px) {
	.banner-title {
		padding-top: 20px;
	}

	.banner-title H1 {
		font-size: 40px;
		line-height: 52px;
		margin-bottom: 22px;
		font-weight: 600;
		font-family: Roboto, sans-serif;
		color: #FFC907;

	}

	.banner-title span {
		color: #FFF;
		font-size: 35px;
		text-shadow: 0px 6.532px 6.532px rgba(0, 0, 0, 0.25);
		font-family: Roboto, sans-serif;
		font-weight: 500;
		margin-top: 20px;

	}

	.banner-title .btn,
	.banner-title .btn-2 {
		padding: 12px 52px;
		border-radius: 50px;
		text-align: center;
		font-size: 15px;
		color: #fff;
		text-transform: capitalize;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		transition: all 0.3s ease;
		font-family: "Roboto", sans-serif;
		font-weight: 700;
		border: 1px solid #FFC907;
		background-color: #FFC907;
		margin-bottom: 20px;
		display: block;
		margin-left: 0px;
	}
}

@media (max-width: 768px) {
	.header-top .htop-area .htop-left .htop-information {
		display: block;
	}
	.header-top .htop-area {
		display: none;
	}
	.newslater-title {
	
		display: none;
	}
	.pt-120 {
		padding-top: 0px;
	}
	.pb-200 {
		padding-bottom: 0px;
	}
	.newslater-area {
		 height: 0px; 
		 margin-bottom: 0px
	}

	.banner-title {
		padding-top: 20px;
	}

	.banner-title H1 {
		font-size: 40px;
		line-height: 52px;
		margin-bottom: 22px;
		font-weight: 600;
		font-family: Roboto, sans-serif;
		color: #FFC907;

	}

	.banner-title span {
		color: #FFF;
		font-size: 35px;
		text-shadow: 0px 6.532px 6.532px rgba(0, 0, 0, 0.25);
		font-family: Roboto, sans-serif;
		font-weight: 500;
		margin-top: 20px;

	}

	.banner-title .btn,
	.banner-title .btn-2 {
		padding: 12px 52px;
		border-radius: 50px;
		text-align: center;
		font-size: 15px;
		color: #fff;
		text-transform: capitalize;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		transition: all 0.3s ease;
		font-family: "Roboto", sans-serif;
		font-weight: 700;
		border: 1px solid #FFC907;
		background-color: #FFC907;
		margin-bottom: 20px;
		display: flex;
		margin-left: 0px;
	}
}